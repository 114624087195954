<template>
  <div class="activation">
    <basics v-if="!resultDialog" v-model="parentShow" @close="change">
      <div slot="header" class="header flex-start flex-item pb-2x bd-b">
        <img
          class="header-img"
          :src="detail.skuImages ? detail.skuImages : detail.productImage"
        />
        <div class="font-xl ml-3x">
          <p>{{ detail.productName }}</p>
          <p class="gray mid-text">
            门票类型：{{
              detail.productTypeName
                ? detail.productTypeName
                : detail.productCategoryText
            }}
          </p>
          <span>票号：{{ detail.auxiliaryId }}</span>
        </div>
      </div>
      <div slot="content" class="ph-4x font-xl mv-4x">
        <a-row class="mb-4x">
          <a-col :span="6">门票激活：</a-col>
          <a-col :span="18">
            <a-checkbox disabled class="font-xl" v-model="activation"
              >立即激活</a-checkbox
            >
          </a-col>
        </a-row>
        <template
          v-if="
            activateInfo.subTicketListInfo &&
              activateInfo.subTicketListInfo.length
          "
        >
          <div v-for="item in activateInfo.subTicketListInfo" :key="item.id">
            <a-row class="mb-4x" v-if="activateInfo.byTicketId">
              <a-col :span="6">身份证号码：</a-col>
              <a-col :span="18">
                <IdCardInput v-model="item.bindIdCardNo"></IdCardInput>
              </a-col>
            </a-row>
            <a-row class="mb-4x" v-if="activateInfo.byFaces">
              <a-col :span="6">人脸信息：</a-col>
              <a-col :span="18">
                <FaceInput v-model="item.bindFaceId"></FaceInput>
              </a-col>
            </a-row>
          </div>
        </template>
        <template v-else>
          <a-row class="mb-4x" v-if="activateInfo.byTicketId">
            <a-col :span="6">身份证号码：</a-col>
            <a-col :span="18">
              <IdCardInput v-model="activateInfo.bindIdCardNo"></IdCardInput>
            </a-col>
          </a-row>
          <a-row class="mb-4x" v-if="activateInfo.byFaces">
            <a-col :span="6">人脸信息：</a-col>
            <a-col :span="18">
              <FaceInput v-model="activateInfo.bindFaceId"></FaceInput>
            </a-col>
          </a-row>
        </template>
      </div>
      <div slot="footer" class="footer flex-center set-border">
        <a-button type="primary" class="btn" @click="onConfirm">
          确定激活
        </a-button>
      </div>
    </basics>
    <Result
      :vis.sync="resultDialog"
      text="要继续操作请点击关闭"
      :show-left-btn="true"
      @update:vis="remain"
      @customAction="customAction"
    ></Result>
  </div>
</template>
<script>
import { findTicketActivate } from "@/api/order";
import Basics from "@/components/dialog/basics";
import FaceInput from "@/components/hardware/face-input";
import IdCardInput from "@/components/hardware/id-card-input";
import { isIdcard } from "@/utils/global";
import Result from "../../../../components/dialog/result.vue";
import { mapGetters } from "vuex";

export default {
  name: "activation",
  components: {
    Basics,
    FaceInput,
    IdCardInput,
    Result
  },
  props: {
    activateInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      parentShow: true,
      activation: true,
      resultDialog: false
    };
  },
  computed: {
    ...mapGetters("setting", ["menuData"])
  },
  methods: {
    change() {
      this.$parent.searchTicketIndex = "";
      this.$emit("closeActive");
    },
    async onConfirm() {
      if (this.validate()) {
        await this.findTicketActivate();
        this.resultDialog = true;
        // this.$parent.searchTicketIndex = "";
      }
    },
    // 关闭结果弹窗
    remain() {
      this.$parent.searchTicketIndex = "";
      this.$emit("closeActive");
    },
    customAction() {
      this.$parent.searchTicketIndex = "";
      this.$emit("closeActive");
      const url = this.menuData[0].url;
      this.$router.replace(url);
    },
    /**
     * @description 验证身份证人脸
     */
    validate() {
      const activateInfo = this.activateInfo;
      let flag = true;
      if (
        activateInfo.subTicketListInfo &&
        activateInfo.subTicketListInfo.length
      ) {
        for (let i in activateInfo.subTicketListInfo) {
          const item = activateInfo.subTicketListInfo[i];
          if (activateInfo.byTicketId && isIdcard(item.bindIdCardNo)) {
            this.$message.error(`请输入${+i + 1}号人员正确的身份证号`);
            flag = false;
            return flag;
          }
          if (activateInfo.byFaces && !item.bindFaceId) {
            this.$message.error(`请录入${+i + 1}号人员人脸信息`);
            flag = false;
            return flag;
          }
        }
      } else {
        if (activateInfo.byTicketId && isIdcard(activateInfo.bindIdCardNo)) {
          this.$message.error(`请输入正确的身份证号`);
          flag = false;
          return flag;
        }
        if (activateInfo.byFaces && !activateInfo.bindFaceId) {
          this.$message.error(`请录入人脸信息`);
          flag = false;
          return flag;
        }
      }
      return flag;
    },
    /**
     * @description 门票激活
     */
    async findTicketActivate() {
      const activateInfo = this.activateInfo;
      let ticketPrintSubAuxiliaryInfoList;
      if (
        activateInfo.subTicketListInfo &&
        activateInfo.subTicketListInfo.length
      ) {
        ticketPrintSubAuxiliaryInfoList = activateInfo.subTicketListInfo.map(
          item => {
            return {
              id: item.id,
              idCardNo: item.bindIdCardNo,
              faceId: item.bindFaceId
            };
          }
        );
      }
      const params = {
        auxiliaryInfoList: [
          {
            ifActivateNow: this.activation,
            id: activateInfo.id,
            auxiliaryId: activateInfo.auxiliaryId,
            idCardNo: activateInfo.bindIdCardNo,
            faceId: activateInfo.bindFaceId,
            ticketPrintSubAuxiliaryInfoList
          }
        ]
      };
      await findTicketActivate(params);
      this.$message.success("激活成功");
      this.$emit("on-refresh");
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.header-img {
  width: 160px;
  height: 160px;
  border-radius: @2x;
}
.mid-text {
  line-height: 60px;
}

.ul-wrap {
  max-height: 1000px;
  .content-img {
    width: 150px;
    height: 150px;
    border-radius: @2x;
  }
}

.refund-btn {
  width: 200px;
  height: 60px;
  border-radius: 40px;
}

/deep/.ant-checkbox-inner {
  width: 30px;
  height: 30px;
  &::after {
    width: 8px;
    height: 18px;
  }
}
</style>
