<template>
  <div class="fast-track">
    <basics v-model="parentShow" @close="change">
      <div slot="header" class="header flex-start flex-item pb-2x bd-b">
        <img class="header-img" :src="detail.skuImages" />
        <div class="font-xl ml-3x">
          <p>{{ detail.productName }}</p>
          <p class="gray mid-text">门票类型：{{ detail.productTypeName }}</p>
          <span>票号：{{ detail.auxiliaryId }}</span>
        </div>
      </div>
      <div slot="content">
        <Refund @refund="refundEvent" :detail="detail"></Refund>
      </div>
    </basics>
    <RefundTips
      :types="tipsType"
      v-model="showTips"
      @close="refundTips"
    ></RefundTips>
  </div>
</template>
<script>
import Basics from "@/components/dialog/basics";
import Refund from "./refund";
import RefundTips from "./refund-tips";
export default {
  name: "RefundTicket",
  components: {
    Basics,
    Refund,
    RefundTips
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      parentShow: true,
      showTips: false,
      tipsType: 1 // 退款审核类型
    };
  },
  methods: {
    change() {
      this.$parent.searchTicketIndex = "";
    },
    refundEvent(res) {
      // console.log(res, "3131-----*---");
      // // res.refundStatus 退款状态:1退款审核中 2审核驳回 3退款中 4已退款 5退款失败
      if (res.refundStatus == 4) {
        this.tipsType = 1;
      } else if (res.refundStatus == 1) {
        this.tipsType = 2;
      }
      this.parentShow = false;
      this.showTips = true;
    },
    refundTips() {
      this.$parent.searchTicketIndex = "";
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.header-img {
  width: 160px;
  height: 160px;
  border-radius: @2x;
}
.mid-text {
  line-height: 60px;
}

.refund-btn {
  width: 200px;
  height: 60px;
  border-radius: 40px;
}

.ul-wrap {
  max-height: 1000px;
  .content-img {
    width: 150px;
    height: 150px;
    border-radius: @2x;
  }
}

/deep/.ant-modal-footer {
  padding: 10px 16px;
  border-top: 1px solid #e8e8e8;
}
</style>
