<template>
  <div class="fast-track">
    <basics v-model="parentShow" @close="change">
      <div slot="header" class="header flex-start flex-item pb-2x bd-b">
        <img class="header-img" :src="detail.skuImages" />
        <div class="font-xl ml-3x">
          <p>{{ detail.productName }}</p>
          <p class="gray mid-text">门票类型：{{ detail.productTypeName }}</p>
          <span>票号：{{ detail.auxiliaryId }}</span>
        </div>
      </div>
      <div slot="content" class="ph-3x" v-show="!showRefund">
        <p class="font-xl pv-4x flex-between">
          <span>快速通行服务费用：{{ fast.totalAmount | money }}</span>
          <a-button
            class="refund-btn font-l"
            type="primary"
            @click="refundClick"
            >申请退款</a-button
          >
        </p>
        <ul class="slide ul-wrap" v-if="freeProducts && freeProducts.length">
          <li
            class="bd round flex-start flex-item p-2x mb-2x"
            v-for="(item, index) in freeProducts"
            :key="index"
          >
            <img class="content-img flex-shrink" :src="item.productImage" />
            <div class="font-l ml-2x flex-grow">
              <p class="mb-1x flex-between">
                <span>{{ item.productName }}</span>
                <span class="primary">{{ item.statusText }}</span>
              </p>
              <p class="text-1 mb-1x" v-if="item.ticketAsset">
                含：{{ item.ticketAsset }}
              </p>
              <p class="text-1 mb-1x" v-if="item.canUsePlayItem">
                可玩项目：{{ item.canUsePlayItem }}
              </p>
              <p class="gray text-1" v-if="item.canUseNum">
                剩余使用次数：{{
                  item.canUseNum == -1 ? "不限" : item.canUseNum - item.useNum
                }}
              </p>
            </div>
          </li>
        </ul>
        <p class="font-xxl text-c bd-t gray pt-2x" v-else>无赠送产品</p>
      </div>
      <div slot="content" v-if="showRefund">
        <Refund @refund="refundEvent"></Refund>
      </div>
    </basics>
    <RefundTips v-model="showTips" @close="refundTips"></RefundTips>
  </div>
</template>
<script>
import Basics from "@/components/dialog/basics";
import Refund from "./refund";
import RefundTips from "./refund-tips";

import { ticketFastWithProductList, refundCheck } from "../../../../api/order";

export default {
  name: "FastTrack",
  components: {
    Basics,
    Refund,
    RefundTips
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      parentShow: true,
      showRefund: false,
      showTips: false,
      fast: {}, // 快速通行服务
      freeProducts: [] // 快速通行赠送产品
    };
  },
  created() {
    ticketFastWithProductList({
      id: this.detail.id
    })
      .then(res => {
        this.fast = res.ticketFastProductDetailInfoVO || {}; // 基础信息
        this.freeProducts = res.orderDetailProductVOS || []; // 套餐信息
      })
      .catch(() => {});
  },
  methods: {
    // 申请退款
    refundClick() {
      console.log(1111);
      refundCheck({
        orderId: this.detail.mainOrderId,
        detailIdType: 2,
        detailIdList: [this.fast.id]
      })
        .then(res => {
          this.$store.dispatch("search/setRefund", res);
          this.showRefund = true;
        })
        .catch(() => {});
    },
    change() {
      this.$parent.searchTicketIndex = "";
    },
    // 退款组件 回调方法
    refundEvent(res) {
      // // res.refundStatus 退款状态:1退款审核中 2审核驳回 3退款中 4已退款 5退款失败
      if (res.refundStatus == 4) {
        this.tipsType = 1;
      } else if (res.refundStatus == 1) {
        this.tipsType = 2;
      }
      this.parentShow = false;
      this.showTips = true;
    },
    refundTips() {
      this.$parent.searchTicketIndex = "";
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.header-img {
  width: 160px;
  height: 160px;
  border-radius: @2x;
}
.mid-text {
  line-height: 60px;
}

.refund-btn {
  width: 200px;
  height: 60px;
  border-radius: 40px;
}

.ul-wrap {
  max-height: 1000px;
  .content-img {
    width: 150px;
    height: 150px;
    border-radius: @2x;
  }
}

/deep/.ant-modal-footer {
  padding: 10px 16px;
  border-top: 1px solid #e8e8e8;
}
</style>
