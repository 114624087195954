<template>
  <basics v-model="parentShow" @close="change">
    <div slot="header" class="header flex-start flex-item pb-2x bd-b">
      <img class="header-img" :src="detail.skuImages" />
      <div class="font-xl ml-3x">
        <p>{{ detail.productName }}</p>
        <p class="gray mid-text">门票类型：{{ detail.productTypeName }}</p>
        <span>票号：{{ detail.auxiliaryId }}</span>
      </div>
    </div>
    <div slot="content">
      <p class="text-c font-xl pv-2x">门票核销记录</p>

      <ul class="slide ul-wrap" v-if="recordList && recordList.length">
        <li
          class="bd round flex-start flex-item p-2x mb-2x"
          v-for="(item, index) in recordList"
          :key="index"
        >
          <p class="flex-around font-xl res">
            <span
              >核销时间：{{
                item.writeOffTime | date("yyyy-mm-dd hh:mm:ss")
              }}</span
            >
            <span>核销渠道：{{ item.writeOffChannelText }}</span>
          </p>
        </li>
      </ul>

      <a-empty class="mt-4x" v-else></a-empty>
    </div>
  </basics>
</template>
<script>
import Basics from "@/components/dialog/basics";
import { findTicketWriteOffInfoPage } from "../../../../api/order";

export default {
  name: "writeoff",
  components: {
    Basics
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      parentShow: true,
      recordList: []
    };
  },
  created() {
    findTicketWriteOffInfoPage({
      page: 1,
      size: 9999,
      id: this.detail.id, // 门票订单详情记录id
      writeOffTime: "" // 核销日期
    })
      .then(res => {
        this.recordList = res.records || [];
      })
      .catch(() => {});
  },
  methods: {
    change() {
      this.$parent.searchTicketIndex = "";
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.header-img {
  width: 160px;
  height: 160px;
  border-radius: @2x;
}
.mid-text {
  line-height: 60px;
}

.ul-wrap {
  max-height: 1000px;
  .content-img {
    width: 150px;
    height: 150px;
    border-radius: @2x;
  }
}

.refund-btn {
  width: 200px;
  height: 60px;
  border-radius: 40px;
}
</style>
