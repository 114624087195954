<!--
综合套票子产品
-->
<template>
  <div>
    <basics v-model="parentShow" @close="change">
      <div slot="header" class="header flex-start flex-item pb-2x bd-b">
        <img class="header-img" :src="detail.skuImages" />
        <div class="font-xl ml-3x">
          <p>{{ detail.productName }}</p>
          <p class="gray mid-text">门票类型：{{ detail.productTypeName }}</p>
          <span>票号：{{ detail.auxiliaryId }}</span>
        </div>
      </div>
      <div slot="content">
        <p class="text-c font-xl pv-2x">综合套票子产品</p>
        <ul class="slide ul-wrap">
          <li
            class="bd round flex-start flex-item p-2x mb-2x"
            v-for="(item, index) in list"
            :key="index"
          >
            <img class="content-img" :src="item.productImage" />
            <div class="font-l ml-2x flex-grow">
              <p class="mb-1x flex-between">
                <span>{{ item.productName }}</span>
                <span class="primary">
                  {{ item.statusText }}
                  <span
                    @click="activeClick(item)"
                    class="ml-2x"
                    v-if="item.status == 4"
                    >立即激活</span
                  >
                </span>
              </p>
              <p class="text-1 mb-1x" v-if="item.ticketAsset">
                含：{{ item.ticketAsset }}
              </p>
              <p class="text-1 mb-1x" v-if="item.canUsePlayItem">
                可玩项目：{{ item.canUsePlayItem }}
              </p>
              <p class="gray text-1" v-if="item.canUseNum">
                剩余使用次数：{{
                  item.canUseNum == -1 ? "不限" : item.canUseNum - item.useNum
                }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </basics>
    <Activation
      v-if="showActive"
      :detail="activeDetail"
      :activate-info="activateInfo"
      @closeActive="closeActive"
      @on-refresh="onRefresh"
    ></Activation>
  </div>
</template>
<script>
import Basics from "@/components/dialog/basics";
import {
  ticketPackageProductList,
  findTicketActivateInfo
} from "../../../../api/order";
import Activation from "./activation.vue";

export default {
  name: "PackageTicket",
  components: {
    Basics,
    Activation
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      parentShow: true,
      list: [], // 子产品
      activateInfo: {}, // 门票激活查询到的数据
      activeDetail: {}, // 当前要激活门票的数据
      showActive: false
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      ticketPackageProductList({
        id: this.detail.id
      })
        .then(res => {
          this.list = res || [];
          console.log(res, " 0011---966--*----");
        })
        .catch(() => {});
    },
    change() {
      this.$parent.searchTicketIndex = "";
    },
    closeActive() {
      this.showActive = false;
    },
    activeClick(data) {
      console.log(data, "--*--002233699");
      this.activeDetail = data;
      this.findActivateInfo(data.id);
      this.showActive = true;
    },
    // 人脸、激活信息查询
    async findActivateInfo(id) {
      const res = await findTicketActivateInfo({
        id
      }).catch(() => {});

      // 没有子票号场景
      res && (this.activateInfo = res);
      // 有子票号场景
      if (res.subTicketListInfo) {
        const deepCopyRes = JSON.parse(JSON.stringify(res));
        deepCopyRes.subTicketListInfo.forEach(item => {
          item.subIdCardNumber = ""; // 存储硬件读取的身份证
          item.subFaceNumber = ""; // 存储硬件读取的人脸
        });
        this.activateInfo = deepCopyRes;
      }
    },
    onRefresh() {
      this.getData();
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.header-img {
  width: 160px;
  height: 160px;
  border-radius: @2x;
}
.mid-text {
  line-height: 60px;
}

.ul-wrap {
  max-height: 1000px;
  .content-img {
    width: 150px;
    height: 150px;
    border-radius: @2x;
  }
}
</style>
