<template>
  <div class="choice-tickets wrapper hei">
    <!-- 返回菜单 -->
    <back text="可选门票"></back>
    <!-- 门票列表 -->
    <ul class="container slide ul-wrp flex-grow">
      <li
        class="card flex-start relative"
        v-for="(item, index) in ticketList"
        :key="index"
        @click="goToDetail(item)"
      >
        <img class="ticket-img mr-3x" :src="item.skuImages" />
        <div class="card-text-wrap">
          <p class="font-xl text-1 line-height">
            {{ item.productName }}
          </p>
          <div class="font-l gray flex-start line-height">
            门票类型：{{ item.productTypeName }}
          </div>
          <div class="line-height flex-between">
            <span class="gray">票号：{{ item.auxiliaryId }}</span>
            <span class="primary font-l ml-1x">
              <a-icon type="check-circle" />
              点击查看
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import Back from "@/components/common/back";
export default {
  name: "ChoiceTickets",
  components: {
    Back
  },
  props: {},
  data() {
    return {
      ticketList: []
    };
  },
  created() {
    this.ticketList = this.$store.state.search.ticketList || [];
  },
  methods: {
    goToDetail(val) {
      this.$router.push({ name: "ticket-detail", params: { detail: val } });
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
.ul-wrp {
  padding-top: 0;
  margin-top: @3x;
}
.ticket-img {
  width: 250px;
  height: 250px;
  border-radius: @2x;
}
.card-text-wrap {
  max-width: 550px;
  .line-height {
    line-height: 82px;
  }
  .notice {
    width: 180px;
  }
  .num-box {
    position: absolute;
    right: @3x;
    bottom: @2x;
  }
}
</style>
