<template>
  <div class="wrapper hei">
    <!-- 扫码提示语 -->
    <tips subtit="开始门票查询"></tips>
    <!-- 键盘与版本号 -->
    <div class="res pv-4x bg-gray-dark keybord flex-grow">
      <keyboard
        v-model="numbers"
        @ok="ok"
        place="请扫码或输入票号/取票号/订单号/手机号/身份证编号"
        @clear="clear"
        :open-idcard="true"
      >
        <!-- <a-input
          slot="input"
          allow-clear
          v-model="numbers"
          placeholder="请扫码或输入票号/取票号/订单号/手机号/身份证编号"
        >
        </a-input> -->
      </keyboard>
    </div>
    <!-- 版本号 -->
    <bottom-ota></bottom-ota>
    <!-- 查询结果弹窗 -->
    <Result
      :vis.sync="resultDialog"
      :type="resultType"
      :text="resultText"
    ></Result>
  </div>
</template>
<script>
import Tips from "@/components/common/tips";
import Keyboard from "@/components/common/keyboard";
import BottomOta from "@/components/common/bottom-ota";

import { orderSelfQueryticket } from "../../api/search";
import Result from "../../components/dialog/result.vue";

export default {
  name: "Search",
  components: {
    Tips,
    Keyboard,
    BottomOta,
    Result
  },
  props: {},
  data() {
    return {
      numbers: "",
      resultDialog: false,
      resultType: 1,
      resultText: ""
    };
  },
  created() {
    this.$speak("请将IC卡放置在下方读卡器或将门票二维码对准右下角扫描器");
  },
  methods: {
    // 键盘确定
    ok() {
      if (!this.numbers) {
        this.$speak("请先输入号码");
        this.resultText = "请先输入号码";
        this.resultType = 1;
        this.resultDialog = true;
        return;
      }

      orderSelfQueryticket({
        keyword: this.numbers // 订单号/取票号/票号/取票人手机号/取票人身份证
      })
        .then(res => {
          if (!res || res.length <= 0) {
            this.$speak("查询不到对应门票");
            this.resultText = "查询不到可取门票";
            this.resultType = 2;
            this.resultDialog = true;
            this.numbers = "";
            return;
          }
          if (res && res.length == 1) {
            // 只查询到一张门票 场景
            this.$router.push({
              name: "ticket-detail",
              params: { detail: res[0] }
            });
          } else if (res && res.length > 1) {
            // 查询到多张门票 场景
            this.$store.dispatch("search/setTicketLis", res);
            this.$router.push({
              name: "choice-tickets"
            });
          }
        })
        .catch(() => {
          this.numbers = "";
        });
    },
    clear() {
      this.numbers = "";
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
.keybord {
  padding-left: @1x * 10;
  padding-right: @1x * 10;
  box-sizing: border-box;
}
</style>
