<template>
  <basics v-model="parentShow" @close="change">
    <div slot="header" class="header flex-start flex-item pb-2x bd-b">
      <img class="header-img" :src="detail.skuImages" />
      <div class="font-xl ml-3x">
        <p>{{ detail.productName }}</p>
        <p class="gray mid-text">门票类型：{{ detail.productTypeName }}</p>
        <span>票号：{{ detail.auxiliaryId }}</span>
      </div>
    </div>

    <div slot="content" class="mt-2x">
      <a-form-model
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        label-align="left"
        ref="refAddFaceInfor"
      >
        <a-form-model-item label="门票名称">
          <span class="font-xxl text-1">{{ activateInfo.productName }}</span>
        </a-form-model-item>

        <!-- 主票：补充人脸 -->
        <a-form-model-item
          :colon="false"
          label="主票票号"
          :label-col="labelCol"
          v-if="
            !activateInfo.subTicketListInfo ||
              activateInfo.subTicketListInfo.length <= 0
          "
        >
          <a-row type="flex" justify="start">
            <a-col :span="7" class="font-xxl">
              {{ activateInfo.auxiliaryId }}
            </a-col>
          </a-row>
          <a-row type="flex" justify="start">
            <a-col :span="17">
              <IdCardInput v-model="idCardNumber"></IdCardInput>
            </a-col>
          </a-row>
          <a-row class="mt-2x" type="flex" justify="start">
            <a-col :span="17">
              <FaceInput @imgUrl="imgUrl" v-model="faceNumber"></FaceInput>
            </a-col>
          </a-row>
        </a-form-model-item>

        <!-- 子票：补充人脸 -->
        <a-form-model-item
          v-if="
            activateInfo.subTicketListInfo &&
              activateInfo.subTicketListInfo.length
          "
          label="子票票号"
        >
          <div
            v-for="(item, index) in activateInfo.subTicketListInfo"
            :key="index"
            class="mb-3x"
          >
            <a-row type="flex" justify="start">
              <a-col :span="6">
                {{ item.auxiliaryId }}
              </a-col>
              <a-col :span="18">
                <IdCardInput v-model="item.bindIdCardNo"></IdCardInput>
              </a-col>
            </a-row>
            <a-row class="mt-x" type="flex" justify="start">
              <a-col :span="6"></a-col>
              <a-col :span="18">
                <FaceInput
                  @imgUrl="
                    url => {
                      subImgUrl(url, index);
                    }
                  "
                  v-model="item.bindFaceId"
                ></FaceInput>
              </a-col>
            </a-row>
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div slot="footer" class="footer flex-center set-border">
      <a-button type="primary" class="btn" @click="sure">
        保存人脸信息
      </a-button>
    </div>
  </basics>
</template>
<script>
import Basics from "@/components/dialog/basics";
import FaceInput from "@/components/hardware/face-input";
import IdCardInput from "../../../../components/hardware/id-card-input.vue";
import { ticketActivateFace } from "../../../../api/order";
import { isIdcard } from "@/utils/global";

export default {
  name: "addFace",
  components: {
    Basics,
    FaceInput,
    IdCardInput
  },
  props: {
    activateInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      parentShow: true,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {},
      rules: {},
      idCardNumber: "", // 身份证输入框
      faceNumber: "", // 人脸信息框
      faceUrl: ""
    };
  },
  methods: {
    change() {
      this.$parent.searchTicketIndex = "";
    },
    // 主票号-人脸图片
    imgUrl(url) {
      this.faceUrl = url;
    },
    // 子票号 - 人脸图片
    subImgUrl(url, index) {
      this.activateInfo.subTicketListInfo[index].bindFaceURL = url;
    },
    // 保存
    async sure() {
      if (this.validate()) {
        await this.interface();
        this.$parent.searchTicketIndex = "";
      }
    },
    async interface() {
      const activateInfo = this.activateInfo;
      let ticketPrintSubAuxiliaryInfoList;
      if (
        activateInfo.subTicketListInfo &&
        activateInfo.subTicketListInfo.length
      ) {
        ticketPrintSubAuxiliaryInfoList = activateInfo.subTicketListInfo.map(
          item => {
            return {
              id: item.id,
              idCardNo: item.bindIdCardNo,
              faceId: item.bindFaceId,
              faceUrl: item.bindFaceURL
            };
          }
        );
      }
      const params = {
        auxiliaryInfoList: [
          {
            ifActivateNow: false, // 是否立即激活
            id: activateInfo.id, // 门票订单详情记录id
            auxiliaryId: activateInfo.auxiliaryId, // 主票号
            idCardNo: this.idCardNumber, // 主票号绑定的身份证号
            faceId: this.faceNumber, // 主票号绑定的人脸id
            faceUrl: this.faceUrl, // 人脸图片地址
            ticketPrintSubAuxiliaryInfoList
          }
        ]
      };
      console.log("补录人脸提交的信息", params);
      await ticketActivateFace(params);
      this.$message.success("补录人脸信息成功");
      this.$emit("on-refresh");
    },
    /**
     * @description 验证身份证人脸
     */
    validate() {
      const activateInfo = this.activateInfo;
      let flag = true;
      if (
        activateInfo.subTicketListInfo &&
        activateInfo.subTicketListInfo.length
      ) {
        for (let i in activateInfo.subTicketListInfo) {
          const item = activateInfo.subTicketListInfo[i];
          if (isIdcard(item.bindIdCardNo)) {
            this.$message.warning(`请检查${+i + 1}项的身份证号是否正确`);
            flag = false;
            return flag;
          }
          if (!item.bindFaceId) {
            this.$message.warning(`请录入${+i + 1}号人员人脸信息`);
            flag = false;
            return flag;
          }
        }
      } else {
        if (isIdcard(this.idCardNumber)) {
          this.$message.warning(`请输入正确的身份证号`);
          flag = false;
          return flag;
        }
        if (!this.faceNumber) {
          this.$message.warning(`请录入人脸信息`);
          flag = false;
          return flag;
        }
      }
      return flag;
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.header-img {
  width: 160px;
  height: 160px;
  border-radius: @2x;
}
.mid-text {
  line-height: 60px;
}

.ul-wrap {
  max-height: 1000px;
  .content-img {
    width: 150px;
    height: 150px;
    border-radius: @2x;
  }
}

.refund-btn {
  width: 200px;
  height: 60px;
  border-radius: 40px;
}
</style>
