<template>
  <Basics hideDefaultHeader v-model="parentShow" @close="change">
    <div slot="content">
      <p class="font-xxl text-c mv-4x">
        <a-icon
          class="mr-1x primary"
          type="check-circle"
          theme="filled"
        ></a-icon>
        <span>{{ types == 1 ? "退款成功" : "提交申请成功" }}</span>
      </p>
      <p class="gray font-l text-c mv-4x">
        {{
          types == 1
            ? "退款将原路退回，2-5个工作日内退回原支付账户"
            : "审核通过后，退款将原路退回，2-5个工作日内退回原支付账户"
        }}
      </p>
    </div>
  </Basics>
</template>
<script>
import Basics from "@/components/dialog/basics.vue";
export default {
  name: "RefundTips",
  components: { Basics },
  model: {
    prop: "show",
    event: "close"
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    // 提示类型：1（退款不需要审核）；2（退款需要审核）
    types: {
      type: [String, Number],
      default: 1
    }
  },
  data() {
    return {
      parentShow: false
    };
  },
  watch: {
    show(val) {
      if (val) {
        this.parentShow = true;
      }
    }
  },
  methods: {
    // 右上角，关闭弹窗按钮
    change() {
      this.$emit("close", false);
    }
  }
};
</script>
<style lang="less" scoped></style>
