<template>
  <basics v-model="parentShow" @close="change">
    <div slot="header" class="header flex-start flex-item pb-2x bd-b">
      <img class="header-img" :src="detail.skuImages" />
      <div class="font-xl ml-3x">
        <p>{{ detail.productName }}</p>
        <p class="gray mid-text">门票类型：{{ detail.productTypeName }}</p>
        <span>票号：{{ detail.auxiliaryId }}</span>
      </div>
    </div>
    <div slot="content">
      <p class="text-c font-xl pv-2x">项目消费记录</p>

      <ul class="slide ul-wrap">
        <li
          class="bd round p-2x mb-2x font-xl "
          v-for="(item, index) in projectList"
          :key="index"
        >
          <p class="flex-between">
            <span>{{ item.productName }}</span>
            <span
              >消费时间：{{
                item.createTime | date("yyyy-mm-dd hh:mm:ss")
              }}</span
            >
          </p>
          <p class="text-1">消费详情：{{ item.consumeDetails }}</p>
        </li>
      </ul>
    </div>
  </basics>
</template>
<script>
import Basics from "@/components/dialog/basics";
import { orderSelfProjectConsume } from "../../../../api/search";
export default {
  name: "projectConsume",
  components: {
    Basics
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      parentShow: true,
      projectList: []
    };
  },
  created() {
    orderSelfProjectConsume({
      id: this.detail.id
    })
      .then(res => {
        this.projectList = res || [];
      })
      .catch(() => {});
  },
  methods: {
    change() {
      this.$parent.searchTicketIndex = "";
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.header-img {
  width: 160px;
  height: 160px;
  border-radius: @2x;
}
.mid-text {
  line-height: 60px;
}

.ul-wrap {
  max-height: 1000px;
  .content-img {
    width: 150px;
    height: 150px;
    border-radius: @2x;
  }
}

.refund-btn {
  width: 200px;
  height: 60px;
  border-radius: 40px;
}
</style>
