<template>
  <div class="refund">
    <div class="mt-4x font-xl pl-2x">
      <a-row class="mb-2x">
        <a-col :span="6">退款产品：</a-col>
        <a-col :span="18" v-if="refund.refundableProductList">
          {{ refund.refundableProductList[0].productName }}
        </a-col>
      </a-row>
      <a-row class="mb-2x">
        <a-col :span="6">原收款金额：</a-col>
        <a-col :span="18">{{
          refund.orderReceivedAmountInfo
            ? refund.orderReceivedAmountInfo.totalAmount
            : "" | money
        }}</a-col>
      </a-row>
      <a-row class="mb-2x">
        <a-col :span="6">退票手续费：</a-col>
        <a-col :span="18">{{ refund.totalCommissionAmount | money }}</a-col>
      </a-row>
      <a-row class="mb-2x">
        <a-col :span="6">本次退款金额：</a-col>
        <a-col :span="18" class="red">
          {{ refund.totalRefundableAmount | money }}
        </a-col>
      </a-row>
      <a-row class="mb-2x">
        <a-col :span="6">退款方式：</a-col>
        <a-col :span="18">
          <p class="mb-2x">
            {{ refund.payWayText }}退:
            <span v-if="refund.orderLeftAmountInfo">
              {{
                (refund.orderLeftAmountInfo.payAmount -
                  refund.totalCommissionAmount)
                  | money
              }}
            </span>
          </p>
          <p class="mb-2x">
            会员余额退：
            <span v-if="refund.orderLeftAmountInfo">
              {{ refund.orderLeftAmountInfo.memberBalanceAmount | money }}
            </span>
          </p>
          <p class="mb-2x">
            积分抵扣退：
            <span v-if="refund.orderLeftAmountInfo">
              {{ refund.orderLeftAmountInfo.integralDeductionAmount | money }}
            </span>
          </p>
        </a-col>
      </a-row>
    </div>
    <div class="footer flex-center set-border">
      <a-button type="primary" class="btn" @click="sureSelect">
        确定退款
      </a-button>
    </div>
  </div>
</template>
<script>
import { refund } from "../../../../api/order";

export default {
  name: "Refund",
  components: {},
  props: {
    // 当前退款项
    item: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      refund: {}
    };
  },
  mounted() {
    this.refund = this.$store.state.search.refund || {};
  },
  methods: {
    sureSelect() {
      let obj = {};
      // 门票充值退款
      if (this.item.mainOrderId) {
        obj = {
          orderId: this.item.mainOrderId,
          remarks: "",
          detailIdType: 2,
          detailIdList: [this.item.id]
        };
      } else {
        obj = {
          orderId: this.refund.orderId
        };
      }
      refund(obj)
        .then(res => {
          console.log("退款接口返回的信息+++++", res);
          // res.refundStatus 退款状态:1退款审核中 2审核驳回 3退款中 4已退款 5退款失败
          this.$emit("refund", res);
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped></style>
