<template>
  <div>
    <basics
      v-model="parentShow"
      @close="close"
      :hide-default-header="isScan || isMem"
    >
      <!-- header -->
      <div
        v-if="!isScan && !isMem"
        slot="header"
        class="header flex-start flex-item pb-2x bd-b"
      >
        <img class="header-img" :src="detail.skuImages" />
        <div class="font-xl ml-3x">
          <p>{{ detail.productName }}</p>
          <p class="gray mid-text">门票类型：{{ detail.productTypeName }}</p>
          <span>票号：{{ detail.auxiliaryId }}</span>
        </div>
      </div>
      <!-- content -->
      <div v-if="!isScan && !isMem" slot="content" class="ph-4x font-xl mv-4x">
        <div class="p-4x bd round">
          <span>门票补办费用</span>
          <!-- 永久免费 -->
          <span v-if="reissue.reprintFeeType == 0" class="red ml-4x">
            {{ 0 | money }}
          </span>
          <!-- 有免费次数，免费次数用完，需要转为收费：免费补办次数 大于 已补办次数: 免费 -->
          <span
            class="red ml-4x"
            v-if="
              reissue.reprintFeeType == 1 &&
                reissue.freePrintTimes > reissue.printTimes
            "
          >
            {{ 0 | money }}
          </span>
          <!-- 免费补办次数 大于 已补办次数: 收费 -->
          <span
            class="red ml-4x"
            v-if="
              reissue.reprintFeeType == 1 &&
                reissue.freePrintTimes <= reissue.printTimes
            "
          >
            {{ reissue.printFee | money }}
          </span>
          元
        </div>

        <!-- <div class="flex-around pv-4x font-xl">
          <div
            :class="{ act: active == 1 }"
            class="public flex-col flex-around"
            @click="select(1)"
          >
            <div class="flex-around mt-3x">
              <a-icon class="primary mr-2x myicon" type="wechat" />
              <a-icon class="blue myicon" type="alipay-circle" />
            </div>
            <span>扫码支付</span>
          </div>
          <div
            :class="{ act: active == 2 }"
            class="public flex-col flex-around"
            @click="select(2)"
          >
            <img
              class="mt-3x wallet-img"
              src="../../../../assets/images/wallet.png"
              alt="wallet"
            />
            <span>会员余额</span>
          </div>
        </div> -->
      </div>
      <!-- footer -->
      <div
        v-if="!isScan && !isMem"
        slot="footer"
        class="footer flex-center set-border"
      >
        <a-button type="primary" class="btn" @click="sure">
          确定补办
        </a-button>
      </div>

      <!-- 选择 扫码支付后的弹窗 -->
      <!-- <div slot="content" v-if="isScan" class="text-c">
        <p class="font-l mb-2x">将微信或支付宝支付码</p>
        <p class="font-l mb-2x">对准右下角扫描器</p>
        <h2 class="font-xxxl bold mb-4x">开始扫码付款</h2>
        <img
          src="https://obs-qmp-android.obs.cn-south-1.myhuaweicloud.com/qumaipiao_k1/image/gif/saomaqupiao.gif"
        />
      </div> -->
      <!-- 选择 会员余额支付后的弹窗 -->
      <!-- <div slot="content" v-if="isMem" class=" keyboard-wrap">
        <div v-if="memberInfo.mobile" class="pb-4x">
          <p class="font-l">
            <span class="mr-4x"
              >{{ memberInfo.name }}/{{ memberInfo.mobile }}</span
            >
            <span class="yellow">
              <span class="iconfont icon-dengji font-xl"></span>
              {{ memberInfo.currentLevelName }}
            </span>
          </p>
          <div class="font-l mt-4x gray">
            <span class="mr-x">会员余额：</span>
            <span>
              {{ (memberInfo.buyBalance + memberInfo.giftBalance) | money }}
              （其中购买余额：{{ memberInfo.buyBalance | money }}，赠送余额：{{
                memberInfo.giftBalance | money
              }}）
            </span>
          </div>
        </div>
        <div v-if="memberInfo.mobile" class="font-l mb-2x">
          <span>门票补办费用</span>
          <span v-if="reissue.reprintFeeType == 0" class="red ml-4x">
            {{ 0 | money }}
          </span>
          <span
            class="red ml-4x"
            v-if="
              reissue.reprintFeeType == 1 &&
                reissue.freePrintTimes > reissue.printTimes
            "
          >
            {{ 0 | money }}
          </span>
          <span
            class="red ml-4x"
            v-if="
              reissue.reprintFeeType == 1 &&
                reissue.freePrintTimes <= reissue.printTimes
            "
          >
            {{ reissue.printFee | money }}
          </span>
          元
        </div>
        <keyboard
          v-if="memberInfo.mobile"
          type="1"
          v-model="memberPassword"
          @ok="balancePayment"
          shadow="2px 2px 5px 1px rgba(0, 0, 0, 0.3)"
          :need-voice="false"
          :default-input="false"
        >
          <a-input
            slot="input"
            allow-clear
            type="password"
            v-model="memberPassword"
            placeholder="请输入会员支付密码"
          >
          </a-input>
        </keyboard>
        <keyboard
          v-if="!memberInfo.mobile"
          type="1"
          v-model="numbers"
          @ok="ok"
          shadow="2px 2px 5px 1px rgba(0, 0, 0, 0.3)"
          place="请扫码或输入会员号/会员手机号"
          @clear="clear"
        >
        </keyboard>
      </div> -->
    </basics>
    <PrintTicket :need-back-home="false" v-model="printDialog"></PrintTicket>
  </div>
</template>
<script>
import Basics from "@/components/dialog/basics";
// import keyboard from "@/components/common/keyboard";
import PrintTicket from "@/components/dialog/print-ticket";
import {
  tradePaymentInfoMicropay,
  tradePaymentInfoStatus
  // payOfflineOrder
} from "../../../../api/order";
import { orderSelfTicketprintinfo } from "../../../../api/search";
import {
  getTicketOrReissue,
  discountInfoCalculate
} from "../../../../api/cart";
import { simpleMember } from "../../../../api/member";
import { updatePaper } from "../../../../utils/global";

export default {
  name: "reissue",
  components: {
    Basics,
    // keyboard,
    PrintTicket
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    },
    reissue: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      parentShow: true,
      active: 1,
      isScan: false, // 是否扫码支付
      isMem: false, // 是否会员余额支付
      numbers: "",
      printDialog: false,
      authCode: "", //付款码
      memberInfo: {},
      cartId: "", // 购物车id
      memberPassword: "" // 会员支付密码
    };
  },
  // destroyed() {
  //   if (this.active == 1) {
  //     try {
  //       //关闭监听扫描设备
  //       window.test.optScan(0);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // },
  methods: {
    close() {
      this.$parent.searchTicketIndex = "";
    },
    async sure() {
      // 生成购物车id
      this.cartId = await this.generateCartId();
      this.sureOrder();

      // // 免费补办的，不管选的扫码支付还是会员余额支付，都静默处理（确认订单）
      // if (this.reissue.printFee <= 0) {
      //   // 确认订单
      //   this.sureOrder();
      //   return;
      // }
      // // 补办需要费用，选择扫码支付
      // if (this.active == 1) {
      //   this.$speak("将微信或支付宝付款码对准右下角扫描器");
      //   this.isScan = true;
      //   try {
      //     //开始监听扫描设备
      //     window.test.optScan(1);
      //     //桥接方法供Java调用
      //     window.payScan = this.payScan;
      //   } catch (error) {
      //     console.log(error);
      //   }
      //   return;
      // }
      // // 补办需要费用，选择会员余额支付
      // if (this.active == 2) {
      //   this.isMem = true;
      //   return;
      // }
    },
    // 生成购物车id
    async generateCartId() {
      return new Promise(resolve => {
        getTicketOrReissue({
          productId: this.reissue.productId, // 产品id
          spuId: this.reissue.spuId, // 产品spuid
          businessCategory: 7, // 业务类型，6取票，7补办
          buyChannels: 8, // 购买渠道，窗口端6，自助机8
          ticketNo: this.reissue.auxiliaryId, // 票号
          totalPrice: this.reissue.printFee, // 取票或补办的金额(单位：分)
          remark: "", // 备注
          spuSnapshotId: this.reissue.spuRuleSnapshotId, // spu快照id
          productTravelerList: [] // 出游人列表
        })
          .then(res => {
            resolve(res);
          })
          .catch(() => {});
      });
    },
    /**
     * 确定订单
     * @param {ismember}: ismember:是否为会员余额支付
     * @param {isScanPay}: 是否扫码支付的
     */
    sureOrder(ismember, isScanPay) {
      const param = {
        id: this.cartId.id // 购物车id
      };
      if (ismember) {
        param.memberId = this.memberInfo.id; // 会员id
        param.memberBalancePrice = this.reissue.printFee; // 会员余额抵扣多少钱
        param.memberPaymentPassword = this.memberPassword; // 会员输入的支付密码
      }
      discountInfoCalculate(param)
        .then(res => {
          // 扫码支付
          if (isScanPay) {
            this.doPay(res.orderId);
            return;
          }
          // 补办费用为0(新版支付：免单支付，直接到确认订单步骤即可，不用像旧版要调支付接口)
          if (this.reissue.printFee <= 0) {
            this.$message.success("补办成功,正为您打印门票");
            this.printTicket();
            return;
          }
          this.$store.dispatch("order/setEndOfPayConfig", {
            logoutMember: true,
            backUrl: "/search"
          });
          // 补办有费用，跳转到支付页面
          this.$router.replace({
            name: "scan-pay",
            query: {
              totalFee: res.settlementPrice,
              orderNumber: res.orderId
            }
          });

          // // 现金支付
          // payOfflineOrder({
          //   orderId: res.orderId,
          //   payWay: ismember ? 9 : 3 // 3现金支付,9会员余额支付
          // })
          //   .then(() => {
          //     this.$message.success("补办成功,正为您打印门票");
          //     this.printTicket();
          //   })
          //   .catch(() => {});
        })
        .catch(() => {});
    },
    // 使用余额支付，确定按钮
    balancePayment() {
      if (!this.memberPassword) {
        this.$message.warning("请先输入会员支付密码");
        return;
      }
      this.sureOrder(true);
    },
    // 提供java调用，传回code码参数
    payScan(code) {
      this.authCode = code;
      // 生成订单
      this.sureOrder(false, true);
      // this.doPay();
    },
    // 扫码支付
    doPay(orderId) {
      let data = {
        orderNo: orderId,
        authCode: this.authCode,
        productName: this.detail.productName || "门票补办"
      };
      tradePaymentInfoMicropay(data)
        .then(res => {
          if (res === "PAID") {
            this.printTicket();
          } else if (res === "PAYING") {
            this.getStatusLoop();
          } else {
            console.log(res, "11");
          }
        })
        .catch(err => {
          console.log(err, "22");
          this.authCode = "";
        });
    },
    // 获取支付状态
    getStatusLoop() {
      this.loopTimer = setTimeout(() => {
        tradePaymentInfoStatus({ orderNo: this.detail.orderId })
          .then(res => {
            if (res === "PAYING") {
              this.getStatusLoop();
            } else {
              if (res === "PAID") {
                this.printTicket();
              } else {
                console.log(res, "33");
              }
            }
          })
          .catch(err => {
            console.log(err, "44");
            this.authCode = "";
          });
      }, 1000);
    },
    // 打印门票
    printTicket() {
      // 系统初始化时，是否设置了门票打印模板，项目套餐打印模板
      const Hardware = localStorage.getItem("Hardware");
      let ticketTempId = "";
      if (Hardware) {
        ticketTempId = JSON.parse(Hardware).machinePrintTemplate;
      }
      orderSelfTicketprintinfo({
        orderDetailIds: this.detail.id,
        templateId: ticketTempId
      })
        .then(res => {
          if (res) {
            try {
              this.parentShow = false; // 关闭补办弹窗
              this.printDialog = true; // 显示 打印弹窗
              const bool = window.test.printTicket(JSON.stringify(res));
              if (!bool) {
                this.$speak("打印失败，请联系管理员查看打印机是否连接");
                this.$warning("打印失败，请联系管理员查看打印机是否连接");
                return;
              }
              // 更新纸张数
              updatePaper(1, res.length, this);
              this.$speak("正在打印，请勿离开");
              setTimeout(() => {
                this.close(); // 关闭整个门票补办组件
              }, 3000);
            } catch (error) {
              this.$speak("打印失败");
              console.log(error, "打印失败");
              this.printDialog = false;
            }
          }
        })
        .catch(() => {
          console.log("打印门票接口报错啦啦啦");
        });
    },
    // // 切换支付方式
    // select(type) {
    //   this.active = type;
    // },
    clear() {
      this.numbers = "";
    },
    // 会员余额键盘 OK键
    ok() {
      simpleMember({
        memberMobile: this.numbers
      })
        .then(res => {
          // 会员余额不够支付 补办费用
          if (res.buyBalance + res.giftBalance < this.reissue.printFee) {
            const msg = `当前会员余额:${(res.buyBalance + res.giftBalance) /
              100}，不足支付`;
            this.$message.warning(msg);
            return;
          }
          this.memberInfo = res || {};
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.header-img {
  width: 160px;
  height: 160px;
  border-radius: @2x;
}
.mid-text {
  line-height: 60px;
}

.public {
  width: 170px;
  height: 170px;
  position: relative;
  left: 0;
  top: 0;
  &.act {
    border: 1px solid @border;
    background-image: url("../../../../assets/images/pay_check.png");
    background-repeat: no-repeat;
    background-position: right top;
    border-radius: 4px;
  }
  .myicon {
    font-size: 50px;
  }
  .wallet-img {
    width: 50px;
    height: 50px;
  }
}

.refund-btn {
  width: 200px;
  height: 60px;
  border-radius: 40px;
}

/deep/.ant-checkbox-inner {
  width: 30px;
  height: 30px;
  &::after {
    width: 8px;
    height: 18px;
  }
}

.keyboard-wrap {
  box-sizing: border-box;
  padding: 200px 100px;
}
</style>
