<template>
  <div class="fast-track">
    <basics v-model="parentShow" @close="change">
      <div slot="header" class="header flex-start flex-item pb-2x bd-b">
        <img class="header-img" :src="detail.skuImages" />
        <div class="font-xl ml-3x">
          <p>{{ detail.productName }}</p>
          <p class="gray mid-text">门票类型：{{ detail.productTypeName }}</p>
          <span>票号：{{ detail.auxiliaryId }}</span>
        </div>
      </div>
      <div slot="content" class="ph-3x" v-show="!showRefund">
        <p class="text-c font-xl pv-2x">门票充值产品</p>

        <ul class="slide ul-wrap">
          <li
            class="bd round flex-start flex-item p-2x mb-2x relative"
            v-for="(item, index) in recordList"
            :key="index"
          >
            <img class="content-img" :src="item.productImage" />
            <div class="font-l ml-2x flex-grow">
              <p class="mb-1x flex-between">
                <span>{{ item.productName }}</span>
                <span class="primary">{{ item.statusText }}</span>
              </p>
              <p class="gray text-1 mb-1x" v-if="item.ticketAsset">
                含：{{ item.ticketAsset }}
              </p>
              <p class="text-1 mb-1x" v-if="item.canUsePlayItem">
                可玩项目：{{ item.canUsePlayItem }}
              </p>
              <p class="gray text-1 mb-1x" v-if="item.createTime">
                <span>充值时间：</span>
                <span>{{ item.createTime | date("yyyy-mm-dd hh:mm:ss") }}</span>
              </p>
              <p class="gray text-1 mb-1x" v-if="item.receivedAmount">
                <span>加购金额：</span>
                <span class="red">{{ item.receivedAmount | money }}</span>
              </p>
            </div>
            <a-button
              class="refund-btn font-l"
              type="primary"
              :disabled="item.status < 3 || item.status > 5"
              @click="refundBtnClick(item)"
              >申请退款</a-button
            >
          </li>
        </ul>
      </div>
      <!-- 具体退款信息 -->
      <div slot="content" v-if="showRefund">
        <Refund @refund="refund" :item="refundItem"></Refund>
      </div>
    </basics>
    <!-- 退款是否需要审核：提示语 -->
    <RefundTips v-model="showTips" @close="refundTips"></RefundTips>
  </div>
</template>
<script>
import Basics from "@/components/dialog/basics";
import Refund from "./refund";
import RefundTips from "./refund-tips";
import { ticketRechargeProductList, refundCheck } from "../../../../api/order";

export default {
  name: "RechargeRecord",
  components: {
    Basics,
    Refund,
    RefundTips
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      parentShow: true,
      showRefund: false,
      showTips: false,
      recordList: [], // 充值记录
      refundItem: {} // 当前退款项数据
    };
  },
  created() {
    ticketRechargeProductList({
      id: this.detail.id // 门票订单详情记录id
    })
      .then(res => {
        console.log(res, "232--*/*-----");
        this.recordList = res || [];
      })
      .catch(() => {});
  },
  methods: {
    change() {
      this.$parent.searchTicketIndex = "";
    },
    refund() {
      this.parentShow = false;
      this.showTips = true;
      this.$emit("rechargeRecord");
    },
    refundTips() {
      this.$parent.searchTicketIndex = "";
    },
    // 申请退款
    refundBtnClick(item) {
      console.log(item, "++++-----/******");
      refundCheck({
        orderId: item.mainOrderId, // 订单主编号
        detailIdType: 2, // 详情id类型 1子订单id 2产品详情id
        detailIdList: [item.id] // 子订单id列表或订单详情id列表
      })
        .then(res => {
          this.$store.dispatch("search/setRefund", res);
          this.refundItem = item;
          this.showRefund = true;
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.header-img {
  width: 160px;
  height: 160px;
  border-radius: @2x;
}
.mid-text {
  line-height: 60px;
}

.ul-wrap {
  max-height: 1000px;
  .content-img {
    width: 150px;
    height: 150px;
    border-radius: @2x;
  }
  .refund-btn {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 140px;
    height: 40px;
    border-radius: 40px;
  }
}

/deep/.ant-modal-footer {
  padding: 10px 16px;
  border-top: 1px solid #e8e8e8;
}
</style>
