var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('basics',{on:{"close":_vm.change},model:{value:(_vm.parentShow),callback:function ($$v) {_vm.parentShow=$$v},expression:"parentShow"}},[_c('div',{staticClass:"header flex-start flex-item pb-2x bd-b",attrs:{"slot":"header"},slot:"header"},[_c('img',{staticClass:"header-img",attrs:{"src":_vm.detail.skuImages}}),_c('div',{staticClass:"font-xl ml-3x"},[_c('p',[_vm._v(_vm._s(_vm.detail.productName))]),_c('p',{staticClass:"gray mid-text"},[_vm._v("门票类型："+_vm._s(_vm.detail.productTypeName))]),_c('span',[_vm._v("票号："+_vm._s(_vm.detail.auxiliaryId))])])]),_c('div',{staticClass:"mt-2x",attrs:{"slot":"content"},slot:"content"},[_c('a-form-model',{ref:"refAddFaceInfor",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label-align":"left"}},[_c('a-form-model-item',{attrs:{"label":"门票名称"}},[_c('span',{staticClass:"font-xxl text-1"},[_vm._v(_vm._s(_vm.activateInfo.productName))])]),(
          !_vm.activateInfo.subTicketListInfo ||
            _vm.activateInfo.subTicketListInfo.length <= 0
        )?_c('a-form-model-item',{attrs:{"colon":false,"label":"主票票号","label-col":_vm.labelCol}},[_c('a-row',{attrs:{"type":"flex","justify":"start"}},[_c('a-col',{staticClass:"font-xxl",attrs:{"span":7}},[_vm._v(" "+_vm._s(_vm.activateInfo.auxiliaryId)+" ")])],1),_c('a-row',{attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":17}},[_c('IdCardInput',{model:{value:(_vm.idCardNumber),callback:function ($$v) {_vm.idCardNumber=$$v},expression:"idCardNumber"}})],1)],1),_c('a-row',{staticClass:"mt-2x",attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":17}},[_c('FaceInput',{on:{"imgUrl":_vm.imgUrl},model:{value:(_vm.faceNumber),callback:function ($$v) {_vm.faceNumber=$$v},expression:"faceNumber"}})],1)],1)],1):_vm._e(),(
          _vm.activateInfo.subTicketListInfo &&
            _vm.activateInfo.subTicketListInfo.length
        )?_c('a-form-model-item',{attrs:{"label":"子票票号"}},_vm._l((_vm.activateInfo.subTicketListInfo),function(item,index){return _c('div',{key:index,staticClass:"mb-3x"},[_c('a-row',{attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":6}},[_vm._v(" "+_vm._s(item.auxiliaryId)+" ")]),_c('a-col',{attrs:{"span":18}},[_c('IdCardInput',{model:{value:(item.bindIdCardNo),callback:function ($$v) {_vm.$set(item, "bindIdCardNo", $$v)},expression:"item.bindIdCardNo"}})],1)],1),_c('a-row',{staticClass:"mt-x",attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":6}}),_c('a-col',{attrs:{"span":18}},[_c('FaceInput',{on:{"imgUrl":function (url) {
                    _vm.subImgUrl(url, index);
                  }},model:{value:(item.bindFaceId),callback:function ($$v) {_vm.$set(item, "bindFaceId", $$v)},expression:"item.bindFaceId"}})],1)],1)],1)}),0):_vm._e()],1)],1),_c('div',{staticClass:"footer flex-center set-border",attrs:{"slot":"footer"},slot:"footer"},[_c('a-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":_vm.sure}},[_vm._v(" 保存人脸信息 ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }