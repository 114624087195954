<!-- 推荐加购 -->

<template>
  <div>
    <basics v-model="parentShow" @close="change">
      <div slot="header" class="header flex-start flex-item pb-2x bd-b">
        <img class="header-img" :src="detail.skuImages" />
        <div class="font-xl ml-3x">
          <p>{{ detail.productName }}</p>
          <p class="gray mid-text">门票类型：{{ detail.productTypeName }}</p>
          <span>票号：{{ detail.auxiliaryId }}</span>
        </div>
      </div>
      <div slot="content" v-show="!showRefund">
        <p class="text-c font-xl pv-2x">门票加购产品</p>

        <ul class="slide ul-wrap" v-if="tableData && tableData.length">
          <li
            class="bd round flex-start flex-item p-2x mb-2x"
            v-for="(item, index) in tableData"
            :key="index"
          >
            <img class="content-img flex-shrink" :src="item.productImage" />
            <div class="font-l ml-2x flex-grow">
              <p class="mb-2x flex-between">
                <span>{{ item.productName }}</span>
                <span class="primary">{{ item.statusText }}</span>
              </p>
              <p class="gray text-1 mb-1x" v-if="item.ticketAsset">
                含：{{ item.ticketAsset }}
              </p>
              <p class="text-1 mb-1x gray" v-if="item.canUsePlayItem">
                可玩项目：{{ item.canUsePlayItem }}
              </p>
              <div class="font-xl flex-between">
                <p>
                  <span class="gray">
                    加购金额：
                    <span class="red">{{ item.receivedAmount | money }}</span>
                  </span>
                </p>
                <a-button
                  class="refund-btn font-l"
                  type="primary"
                  @click="applyForRefund(item)"
                  >申请退款</a-button
                >
              </div>
            </div>
          </li>
        </ul>
        <div v-else class="mt-2x">
          <a-empty></a-empty>
        </div>
      </div>

      <div slot="content" v-if="showRefund">
        <Refund @refund="refundEvent"></Refund>
      </div>
    </basics>
    <RefundTips
      :types="tipsType"
      v-model="showTips"
      @close="refundTips"
    ></RefundTips>
  </div>
</template>
<script>
import Basics from "@/components/dialog/basics";
import Refund from "./refund";
import RefundTips from "./refund-tips";
import { ticketAddProductList, refundCheck } from "../../../../api/order";
export default {
  name: "recommend",
  components: {
    Basics,
    Refund,
    RefundTips
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      parentShow: true,
      showRefund: false,
      showTips: false,
      tableData: [],
      tipsType: 1 // 退款审核类型
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // 加载推荐价格产品
    loadData() {
      ticketAddProductList({
        id: this.detail.id
      })
        .then(res => {
          console.log(res, "--加购产品++++");
          this.tableData = res || [];
        })
        .catch(() => {});
    },
    // 申请退款按钮
    applyForRefund(item) {
      refundCheck({
        orderId: this.detail.mainOrderId, // 订单编号
        detailIdType: 2, // 详情id类型 1子订单id 2产品详情id
        detailIdList: [item.id] // 子订单id列表或订单详情id列表
      })
        .then(res => {
          this.$store.dispatch("search/setRefund", res);
          this.showRefund = true;
        })
        .catch(() => {});
    },
    change() {
      this.$parent.searchTicketIndex = "";
    },
    // 退款组件 回调方法
    refundEvent(res) {
      // // res.refundStatus 退款状态:1退款审核中 2审核驳回 3退款中 4已退款 5退款失败
      if (res.refundStatus == 4) {
        this.tipsType = 1;
      } else if (res.refundStatus == 1) {
        this.tipsType = 2;
      }
      this.parentShow = false;
      this.showTips = true;
    },
    refundTips() {
      this.$parent.searchTicketIndex = "";
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.header-img {
  width: 160px;
  height: 160px;
  border-radius: @2x;
}
.mid-text {
  line-height: 60px;
}

.ul-wrap {
  max-height: 1000px;
  .content-img {
    width: 150px;
    height: 150px;
    border-radius: @2x;
  }
}

.refund-btn {
  width: 200px;
  height: 60px;
  border-radius: 40px;
}
</style>
