<template>
  <div class="ticket-detail wrapper hei">
    <!-- 返回菜单 -->
    <back text="门票详情"></back>
    <!-- 主体内容 -->
    <div class="container flex-grow">
      <!-- 门票名称信息 -->
      <div class="card flex-start">
        <img class="ticket-img mr-3x" :src="ticketDetail.skuImages" />
        <div class="card-text-wrap">
          <p class="font-xl text-1 line-height">
            {{ ticketDetail.productName }}
          </p>
          <div class="font-l gray flex-start line-height">
            门票类型：{{ ticketDetail.productTypeName }}
          </div>
          <div class="line-height">
            <span class="gray">票号：{{ ticketDetail.auxiliaryId }}</span>
          </div>
        </div>
      </div>
      <!-- 门票资产 -->
      <div class="ticket-assets mt-2x">
        <p class="pv-3x bd-b">
          <span class="pl-2x">门票资产</span>
        </p>
        <div
          class="box flex-between flex-wrap pv-3x"
          v-if="assetsList.length > 0"
        >
          <p
            class="ml-3x mb-4x"
            v-for="(item, index) in assetsList"
            :key="index"
          >
            <span>{{ item.name }}</span>
            <span class="red ml-4x">{{ item.num / 100 }}</span>
          </p>
        </div>
        <div class="flex-center gray pv-2x" v-else>
          暂无数据
        </div>
      </div>
      <!-- 项目套餐 -->
      <div class="ticket-assets mt-2x">
        <p class="pv-3x bd-b">
          <span class="pl-2x">项目套餐</span>
        </p>
        <ul class="ph-2x pv-3x ul-wrap slide" v-if="packageList.length > 0">
          <li
            v-for="(item, index) in packageList"
            :key="index"
            class="mt-1x bd-b"
            @click="openDialog(item)"
          >
            <p class="flex-between font-xl">
              <span>{{ item.productName }}</span>
              <span class="primary font-xl">
                <a-icon type="check-circle" />
                点击查看
              </span>
            </p>
            <p class="red pv-2x">
              剩余次数：{{
                item.remainUseNum == -1 ? "不限" : item.remainUseNum
              }}
              （套餐可使用次数：{{
                item.canUseNum == -1 ? "不限" : item.canUseNum + "次"
              }}， 已使用 {{ item.useNum }} 次）
            </p>
          </li>
        </ul>
        <p class="flex-center gray pv-2x" v-else>
          暂无数据
        </p>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="footer flex-around container">
      <a-dropdown
        v-model="showSearchTicketBtn"
        :trigger="['click']"
        placement="topCenter"
        v-if="
          ticketDetail.sonProduct == 1 ||
            ticketDetail.fastPass == 1 ||
            ticketDetail.recommend == 1 ||
            ticketDetail.ticketRecharge == 1 ||
            ticketDetail.status == 6 ||
            ticketDetail.projectConsume == 1
        "
      >
        <a-menu slot="overlay" @click="searchTicketClick" class="ph-2x">
          <a-menu-item
            v-if="ticketDetail.sonProduct == 1"
            class="font-l pv-2x bd-b dashed"
            key="1"
          >
            综合套票子产品
          </a-menu-item>
          <a-menu-item
            v-if="ticketDetail.fastPass == 1"
            class="font-l pv-2x bd-b dashed"
            key="2"
          >
            快速通行服务
          </a-menu-item>
          <a-menu-item
            v-if="ticketDetail.recommend == 1"
            class="font-l pv-2x bd-b dashed"
            key="3"
          >
            推荐加购产品
          </a-menu-item>
          <a-menu-item
            v-if="ticketDetail.ticketRecharge == 1"
            class="font-l pv-2x bd-b dashed"
            key="4"
          >
            门票充值记录
          </a-menu-item>
          <a-menu-item
            v-if="ticketDetail.status == 6"
            class="font-l pv-2x bd-b dashed"
            key="5"
          >
            门票核销记录
          </a-menu-item>
          <a-menu-item
            v-if="ticketDetail.projectConsume == 1"
            class="font-l pv-2x"
            key="6"
          >
            项目消费记录
          </a-menu-item>
        </a-menu>
        <a-button class="btn relative" type="primary">
          门票查询
          <a-icon
            :class="{ rotate: showSearchTicketBtn }"
            class="posi-icon"
            type="caret-down"
          />
        </a-button>
      </a-dropdown>
      <a-dropdown
        :trigger="['click']"
        v-model="showOperateTicketBtn"
        placement="topCenter"
        v-if="
          ticketDetail.supplementFace == 1 ||
            ticketDetail.status == 4 ||
            ticketDetail.whetherTakeTicket == 1 ||
            (ticketDetail.status != 6 && ticketDetail.status != 12)
        "
      >
        <a-menu slot="overlay" @click="operateTicketClick">
          <a-menu-item
            v-if="ticketDetail.supplementFace == 1"
            class="font-l pv-2x bd-b dashed"
            key="1"
          >
            补充人脸信息
          </a-menu-item>
          <a-menu-item
            v-if="ticketDetail.status == 4"
            class="font-l pv-2x bd-b dashed"
            key="2"
          >
            门票激活
          </a-menu-item>
          <a-menu-item
            v-if="ticketDetail.whetherTakeTicket == 1"
            class="font-l pv-2x bd-b dashed"
            key="3"
          >
            门票补办
          </a-menu-item>
          <a-menu-item
            v-if="ticketDetail.status != 6 && ticketDetail.status != 12"
            class="font-l pv-2x bd-b dashed"
            key="4"
          >
            退票
          </a-menu-item>
        </a-menu>
        <a-button class="btn relative" type="primary">
          门票操作
          <a-icon
            :class="{ rotate: showOperateTicketBtn }"
            class="posi-icon"
            type="caret-down"
          />
        </a-button>
      </a-dropdown>
    </div>

    <!-- 项目套餐 点击查看弹窗 -->
    <basics v-model="showDialog">
      <div slot="header" class="header flex-start pb-2x bd-b">
        <img class="header-img" :src="curClickPackage.productImages" />
        <div class="font-xl ml-3x">
          <p>{{ curClickPackage.productName }}</p>
          <p class="gray mid-text">项目套餐</p>
        </div>
      </div>
      <div slot="content" class="mt-4x font-l">
        <a-row class="mb-2x">
          <a-col :span="6">产品名称：</a-col>
          <a-col :span="18">{{ curClickPackage.productName }}</a-col>
        </a-row>
        <a-row class="mb-2x">
          <a-col :span="6">可使用次数：</a-col>
          <a-col :span="18">{{
            curClickPackage.canUseNum == -1
              ? "不限"
              : curClickPackage.canUseNum + "次"
          }}</a-col>
        </a-row>
        <a-row class="mb-2x">
          <a-col :span="6">剩余使用次数：</a-col>
          <a-col :span="18" class="red">{{
            curClickPackage.remainUseNum == -1
              ? "不限"
              : curClickPackage.remainUseNum
          }}</a-col>
        </a-row>
        <a-row class="mb-2x">
          <a-col :span="6">每天使用次数：</a-col>
          <a-col
            :span="18"
            v-if="
              curClickPackage.ticketPlayItemPackageConfigInfo &&
                curClickPackage.ticketPlayItemPackageConfigInfo
                  .whetherDailyUseLimitType == 0
            "
            >不限</a-col
          >
          <a-col :span="18" v-else>{{
            curClickPackage.ticketPlayItemPackageConfigInfo
              ? curClickPackage.ticketPlayItemPackageConfigInfo.dailyUseLimit
              : ""
          }}</a-col>
        </a-row>
        <a-row class="mb-2x">
          <a-col :span="6">可玩项目：</a-col>
          <a-col :span="18">
            <a-table
              :pagination="false"
              :columns="columns"
              :data-source="tableData"
              :scroll="{ y: 800 }"
              :rowKey="(record, index) => index"
              v-if="tableData && tableData.length"
            >
              <!-- 最少使用次数 -->
              <template slot="customRelation.lessLimit" slot-scope="scope">
                <span>{{
                  scope.customRelation ? scope.customRelation.lessLimit : "不限"
                }}</span>
              </template>
              <!-- 最多使用次数 -->
              <template slot="customRelation.mostLimit" slot-scope="scope">
                <span>{{
                  scope.customRelation ? scope.customRelation.mostLimit : "不限"
                }}</span>
              </template>
            </a-table>
            <span v-else>全部</span>
          </a-col>
        </a-row>
      </div>
    </basics>

    <!-- 底部按钮：门票查询 一系列弹窗 -->
    <component
      :detail="ticketDetail"
      :reissue="reissueInfo"
      :refund="refundInfo"
      :activate-info="activateInfo"
      :is="searchTicketIndex"
      @on-refresh="onRefresh"
      @rechargeRecord="rechargeRecord"
    />
  </div>
</template>
<script>
import {
  findTicketAssetsList,
  findTicketPlayitempackagePage,
  findOrderDetailProductDetail,
  findTicketActivateInfo,
  findTicketReissueInfo,
  refundCheck
} from "../../api/order";
import { orderSelfQueryticket } from "../../api/search";

import { orderPackageusableProject } from "../../api/search";

const columns = [
  {
    title: "项目名称",
    dataIndex: "followProductName",
    key: "followProductName"
  },
  {
    title: "最少使用次数",
    // dataIndex: "lessLimit",
    key: "lessLimit",
    scopedSlots: { customRender: "customRelation.lessLimit" }
  },
  {
    title: "最多使用次数",
    // dataIndex: "mostLimit",
    scopedSlots: { customRender: "customRelation.mostLimit" },
    key: "mostLimit"
  },
  {
    title: "已消费次数",
    dataIndex: "useNum",
    key: "useNum"
  }
];

// 导入 门票查询、门票操作 一系列的弹窗组件
const result = {};
const requireComponent = require.context(
  "./components/search-operate",
  false,
  /\.vue$/
);
requireComponent.keys().forEach(fileName => {
  result[fileName.replace(/^\.\/(.*)\.\w+$/, "$1")] = requireComponent(
    fileName
  ).default;
});

export default {
  name: "TicketDetail",
  components: {
    Back: () => import("@/components/common/back"),
    Basics: () => import("@/components/dialog/basics")
  },
  props: {},
  data() {
    return {
      columns,
      tableData: [], // 可玩项目
      showDialog: false,
      showSearchTicketBtn: false, // 是否显示底部：门票查询菜单
      showOperateTicketBtn: false, // 是否显示底部：门票操作菜单
      searchTicketIndex: "",

      ticketDetail: {}, // 上一页面传来的门票信息
      // 门票资产
      assetsList: [],
      // 项目套餐列表
      packageList: [],
      // 当前点击的项目套餐
      curClickPackage: {},
      activateInfo: {},

      // 门票补办查询的数据
      reissueInfo: {},
      // 退票，查询到的数据
      refundInfo: {}
    };
  },
  created() {
    if (this.$route.params.detail) {
      this.ticketDetail = this.$route.params.detail;
    }
    this.getAssetsList(); // 获取门票资产
    this.getPlayitempackagePage(); // 获取项目套餐
    this.findActivateInfo(); // 人脸、激活信息查询
  },
  methods: {
    /**
     * @description 刷新数据
     */
    onRefresh() {
      this.orderSelfQueryticket();
    },
    // 门票充值记录 弹窗emit方法
    rechargeRecord() {
      this.getAssetsList();
      this.getPlayitempackagePage();
    },
    // 获取门票资产
    getAssetsList() {
      findTicketAssetsList({
        id: this.ticketDetail.id // 门票订单详情记录id
      })
        .then(res => {
          this.assetsList = res || [];
        })
        .catch(() => {});
    },
    // 获取项目套餐
    getPlayitempackagePage() {
      findTicketPlayitempackagePage({
        id: this.ticketDetail.id, // 门票订单详情记录id
        productName: "",
        page: 1,
        size: 999
      })
        .then(res => {
          this.packageList = res.records || [];
        })
        .catch(() => {});
    },
    // 人脸、激活信息查询
    async findActivateInfo() {
      const res = await findTicketActivateInfo({
        id: this.ticketDetail.id
      }).catch(() => {});

      // console.log(res, "23--0026----------");

      // 没有子票号场景
      res && (this.activateInfo = res);
      // 有子票号场景
      if (res.subTicketListInfo) {
        const deepCopyRes = JSON.parse(JSON.stringify(res));
        deepCopyRes.subTicketListInfo.forEach(item => {
          item.subIdCardNumber = ""; // 存储硬件读取的身份证
          item.subFaceNumber = ""; // 存储硬件读取的人脸
        });
        this.activateInfo = deepCopyRes;
      }
    },
    // 项目套餐：点击查看弹窗
    openDialog(item) {
      // 获取项目套餐信息
      findOrderDetailProductDetail({
        id: item.id // 当前点击行的记录id
      })
        .then(res => {
          this.curClickPackage = res || {};
          // 获取可玩项目
          orderPackageusableProject({
            id: item.productId, // 项目id/产品Id
            orderDetailId: this.ticketDetail.orderId, // 订单id
            page: 1,
            size: 999
          })
            .then(res => {
              console.log("可玩的项目: ", res);
              this.tableData = res.records || [];
              this.showDialog = true;
            })
            .catch(() => {});
        })
        .catch(() => {});
      // 获取可玩项目
    },
    // 底部 门票查询 按钮
    searchTicketClick({ key }) {
      this.showSearchTicketBtn = false;
      switch (key) {
        case "1":
          this.searchTicketIndex = result["package-ticket"];
          break;
        case "2":
          this.searchTicketIndex = result["fast-track"];
          break;
        case "3":
          this.searchTicketIndex = result["recommend"];
          break;
        case "4":
          this.searchTicketIndex = result["recharge-record"];
          break;
        case "5":
          this.searchTicketIndex = result["writeoff"];
          break;
        case "6":
          this.searchTicketIndex = result["project-consume"];
          break;
        default:
          this.searchTicketIndex = "";
          break;
      }
    },
    // 底部 门票操作 按钮
    operateTicketClick({ key }) {
      this.showOperateTicketBtn = false;
      switch (key) {
        case "1":
          this.searchTicketIndex = result["add-face"];
          break;
        case "2":
          this.searchTicketIndex = result["activation"];
          break;
        case "3":
          findTicketReissueInfo({
            id: this.ticketDetail.id // 门票订单详情记录id
          })
            .then(res => {
              this.reissueInfo = res || {};
              res && (this.searchTicketIndex = result["reissue"]);
            })
            .catch(() => {});
          break;
        case "4":
          // 退票
          refundCheck({
            orderId: this.ticketDetail.mainOrderId, // 订单编号
            detailIdType: 2, // 详情id类型 1子订单id 2产品详情id
            detailIdList: [this.ticketDetail.id] // 子订单id列表或订单详情id列表
          })
            .then(res => {
              this.$store.dispatch("search/setRefund", res);
              this.searchTicketIndex = result["refund-ticket"];
            })
            .catch(() => {});
          break;
        default:
          this.searchTicketIndex = "";
          break;
      }
    },
    async orderSelfQueryticket() {
      const res = await orderSelfQueryticket({
        keyword: this.activateInfo.auxiliaryId // 订单号/取票号/票号/取票人手机号/取票人身份证
      });
      if (res && res.length == 1) {
        // 只查询到一张门票 场景
        this.activateInfo = res[0];
      }
      console.log("Queryticket = ", res);
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";

.ticket-img {
  width: 100px;
  height: 100px;
  border-radius: @2x;
}
.card-text-wrap {
  max-width: 550px;
  .line-height {
    line-height: 34px;
  }
  .notice {
    width: 180px;
  }
  .num-box {
    position: absolute;
    right: @3x;
    bottom: @2x;
  }
}

.ticket-assets {
  background-color: #fff;
  border-radius: @2x;
  .box {
    p {
      display: inline-block;
      width: 46%;
    }
  }
}
.ul-wrap {
  max-height: 400px;
}

// 弹窗图片
.header-img {
  width: 160px;
  height: 160px;
  border-radius: @2x;
}
.mid-text {
  line-height: 100px;
}

.posi-icon {
  position: absolute;
  right: 30px;
  top: 22px;
  transition: all 0.5s;
  &.rotate {
    transform: rotate(180deg);
    transition: all 0.5s;
  }
}

/deep/.ant-table {
  font-size: 20px;
}
</style>
